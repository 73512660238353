class homeItemComponent extends Component {

    static name() {
        return "homeItemComponent";
    }

    static componentName() {
        return "homeItemComponent";
    }

    getProps() {
        return ["itemCode"];
    }

    data() {
        return {
            Qty:1,
            reloadComponent:false,
            viewmode:'module-small'
        };
    }

    getWatch() {
        return {
            '$route':'forceUpdate',
            '$store.state.setting':'forceUpdate',
            '$store.state.itemTimeFlag':'forceUpdate',
            '$store.state.appState':'forceUpdate',
        };
    }

    getComputed() {
        return {
            item:function () {
                if(this.reloadComponent)
                    this.reloadComponent=false;
                let homeItem = this.$store.getters.getItemByCode(this.itemCode);
                //homeItem.visible=true;
                return homeItem;
            },
            itemClassObject: function () {
                return {
                    'oo-item-container-list':this.viewmode=='list',
                    'oo-item-container':this.viewmode=='module' || this.viewmode=='module-small',
                    'oo-item-container-small': this.viewmode=='module-small',
                    'oo-item-container-normal': this.viewmode=='module',
                    'col-12': this.viewmode=='list',
                    'col-6 col-sm-6 col-lg-3 px-1 px-lg-2': this.viewmode=='module',
                    'col-6 col-sm-4 col-lg-2 px-1 px-lg-2': this.viewmode=='module-small'
                }
            }
        };
    }

    getMethods() {
        return {
            forceUpdate:this.forceUpdate
        };
    }

    forceUpdate () {
        this.reloadComponent = !this.reloadComponent;
    }

    mounted() {
        return function () {
            if(!this.item)
                this.forceUpdate();
        };
    }

    getTemplate() {
        return `<div :class="itemClassObject" v-if="item">
                <itemViewModuleComponent :item="item" ></itemViewModuleComponent>
              </div>`;
    }
}

homeItemComponent.registerComponent();